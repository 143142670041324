import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import squareImage from '../assets/images/square.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: #020209;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px 20px;
`;

const SquareImage = styled.img`
  width: 280px;
  height: auto;
  margin-bottom: 20px;
`;

const AppName = styled.h1`
  margin-top: 20px;
  color: #fff;
  font-size: 30px;
  font-weight: 300;
`;

const Subtitle = styled.p`
  margin-top: 10px;
  margin-bottom: 30px;
  color: #fff;
  font-size: 16px;
  text-align: center;
`;

const Input = styled.input`
  width: 80%;
  color: #fff;
  font-size: 15px;
  height: 40px;
  border: none;
  border-bottom: 1px solid #fff;
  background: transparent;
  margin-bottom: 20px;
  padding-left: 2px;
  outline: none;
  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const TermsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 80%;
`;

const TermsText = styled.p`
  color: #fff;
  margin-left: 10px;
`;

const Link = styled.a`
  color: #07FBF2;
  text-decoration: underline;
  cursor: pointer;
`;

const ContinueButton = styled.button`
  margin-top: 20px;
  width: 180px;
  height: 45px;
  border: 1px solid #fff;
  border-radius: 25px;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 14px;
`;

const LoginContainer = styled.div`
  margin-top: 20px;
  width: 80%;
  text-align: center;
`;

const TextButton = styled.button`
  color: #fff;
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  border: 1px solid white;
  max-width: 80%;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  text-align: left;
`;

const ModalTitle = styled.h2`
  color: #2450FF;
  margin-bottom: 20px;
`;

const ModalText = styled.p`
  color: #333;
  margin-bottom: 15px;
`;

const CloseButton = styled.button`
  background-color: #2450FF;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
`;

export default function SignUpScreen() {
  const navigate = useNavigate();
  const [nickname, setNickname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);
  const [showTerms, setShowTerms] = useState<boolean>(false);

  const validatePassword = (password: string): boolean => {
    const hasNumber = /\d/;
    const hasUpperCase = /[A-Z]/;
    return password.length >= 7 && hasNumber.test(password) && hasUpperCase.test(password);
  };

  const handleContinue = () => {
    if (!nickname || !email || !password || !confirmPassword) {
      alert('All fields are required.');
      return;
    }

    if (nickname.length > 20) {
      alert('Username cannot be longer than 20 characters.');
      return;
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      alert('Invalid email format.');
      return;
    }

    if (!validatePassword(password)) {
      alert('Password must be at least 7 characters long, contain a number and an uppercase letter.');
      return;
    }

    if (password !== confirmPassword) {
      alert('Passwords do not match.');
      return;
    }

    if (!agreedToTerms) {
      alert('You must agree to the terms of use.');
      return;
    }

    navigate('/after-registration', { state: { nickname, email, password } });
  };

  const handleTermsClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowTerms(true);
  };

  return (
    <Container>
      <ContentWrapper>
        <SquareImage src={squareImage} alt="Square" />
        <AppName>NANDEZU</AppName>
        <Subtitle>Your First AI-Powered Fashion Assistant</Subtitle>

        <Input
          type="text"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNickname(e.target.value)}
          value={nickname}
          placeholder="Username"
        />

        <Input
          type="email"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          value={email}
          placeholder="E-mail"
        />

        <Input
          type="password"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
          value={password}
          placeholder="Password"
        />

        <Input
          type="password"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
          value={confirmPassword}
          placeholder="Confirm Password"
        />

        <TermsContainer>
          <input
            type="checkbox"
            checked={agreedToTerms}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAgreedToTerms(e.target.checked)}
          />
          <TermsText>
            I agree to{' '}
            <Link onClick={handleTermsClick}>
              the terms and conditions
            </Link>
          </TermsText>
        </TermsContainer>

        <ContinueButton onClick={handleContinue}>Continue</ContinueButton>

        <LoginContainer>
          <TextButton onClick={() => navigate('/login')}>
            Already have an account? Login
          </TextButton>
        </LoginContainer>
      </ContentWrapper>

      {showTerms && (
        <ModalOverlay>
          <ModalContent>
            <ModalTitle>Terms and Conditions</ModalTitle>
            <ModalText>
              Welcome to NANDEZU, your AI-powered fashion assistant. These Terms and Conditions govern your use of the NANDEZU application and services. By using our application, you agree to these terms in their entirety.
            </ModalText>
            <ModalText>
              1. User Accounts: You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
            </ModalText>
            <ModalText>
              2. Privacy: We take your privacy seriously. Please refer to our Privacy Policy for information on how we collect, use, and disclose your personal information.
            </ModalText>
            <ModalText>
              3. Intellectual Property: The Application, including but not limited to its software, design, text, graphics, and other content, is owned by NANDEZU and is protected by copyright and other intellectual property laws.
            </ModalText>
            <ModalText>
              4. Prohibited Conduct: You agree not to use the Application for any unlawful purpose or in violation of these Terms.
            </ModalText>
            <ModalText>
              5. Disclaimer of Warranties: The Application and all content and services provided through it are provided on an "as is" and "as available" basis without any warranties of any kind.
            </ModalText>
            <ModalText>
              For the full terms and conditions, please visit our website.
            </ModalText>
            <CloseButton onClick={() => setShowTerms(false)}>Close</CloseButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
  );
}