import React, { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import roboImage from '../assets/images/ROBO.jpg';
import buyImage from '../assets/images/BUY.jpg';
import alloImage from '../assets/images/allo.jpg';
import aifImage from '../assets/images/aif.jpg';
import vafImage from '../assets/images/vaf.jpg';
import cashImage from '../assets/images/cash.jpg';
import vitImage from '../assets/images/vit.jpg';
import bradImage from '../assets/images/brad.jpg';
import scanImage from '../assets/images/scan.jpg';
import aboutImage from '../assets/images/about.jpg';

// Parametry pro změnu velikosti a pozice obrázků
const imageSize = 100; // Velikost obrázků v procentech viewportu
const imagePositions = {
  robo: { left: 5, top: 0 },
  buy: { left: 5, top: 60 },
  allo: { left: 5, top: 11 },
  aif: { left: 5, top: 50},
  vaf: { left: 5, top: 21},
  cash: { left: 5, top: 41 },
  vit: { left: 5, top: 32},
  brad: { left: 5, top: 70 },
  scan: { left: 5, top: 78 },
  about: { left: 5, top: 88 },
  userCount: { left: 40, top: 9 },
  contactForm: { left: 35, top: 99 },
  footer: { left: 0, top: 112 } // Přidána pozice pro patičku
};

const PCHomeScreen: React.FC = () => {
  const [userCount, setUserCount] = useState(27800);
  const [displayCount, setDisplayCount] = useState(27800);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    company: '',
    contactNumber: '',
    message: ''
  });
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const getDaysSinceStart = () => {
      const startDate = new Date('2024-10-06');
      const today = new Date();
      const diffTime = Math.abs(today.getTime() - startDate.getTime());
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };

    const seededRandom = (seed: number) => {
      const x = Math.sin(seed) * 10000;
      return x - Math.floor(x);
    };

    const calculateUserCount = () => {
      const days = getDaysSinceStart();
      let count = 47910;
      for (let i = 0; i < days; i++) {
        count += Math.floor(seededRandom(i) * (650 - 10 + 1) + 10);
      }
      return count;
    };

    setUserCount(calculateUserCount());
  }, []);

  useEffect(() => {
    const animateCount = () => {
      if (displayCount < userCount) {
        setDisplayCount(prevCount => {
          const diff = userCount - prevCount;
          const increment = Math.ceil(diff / 10);
          return Math.min(prevCount + increment, userCount);
        });
      }
    };

    const timer = setInterval(animateCount, 100);

    return () => clearInterval(timer);
  }, [userCount, displayCount]);

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      select option {
        background-color: black;
        color: white;
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px black inset !important;
        -webkit-text-fill-color: white !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
   
    emailjs.send(
      'service_90rceci',
      'template_kczxy1q',
      formData,
      'aC-A40QG7SOL3kDXF'
    )
    .then((result) => {
      console.log('Email successfully sent!', result.text);
      alert('Your message has been sent successfully!');
      setFormData({
        name: '',
        email: '',
        subject: '',
        company: '',
        contactNumber: '',
        message: ''
      });
    }, (error) => {
      console.log('Failed to send email:', error.text);
      alert('Failed to send message. Please try again later.');
    });
  };

  const handleFooterButtonClick = (buttonName: string) => {
    console.log(`Clicked on ${buttonName}`);
    // Zde můžete později přidat skutečnou funkčnost
  };

  return (
    <main style={styles.main}>
      <div style={styles.imageContainer}>
        {[
          { src: roboImage, alt: "ROBO", position: imagePositions.robo },
          { src: buyImage, alt: "BUY", position: imagePositions.buy },
          { src: alloImage, alt: "ALLO", position: imagePositions.allo },
          { src: aifImage, alt: "AIF", position: imagePositions.aif },
          { src: vafImage, alt: "VAF", position: imagePositions.vaf },
          { src: cashImage, alt: "CASH", position: imagePositions.cash },
          { src: vitImage, alt: "VIT", position: imagePositions.vit },
          { src: bradImage, alt: "BRAD", position: imagePositions.brad },
          { src: scanImage, alt: "SCAN", position: imagePositions.scan },
          { src: aboutImage, alt: "ABOUT", position: imagePositions.about }
        ].map((img, index) => (
          <img
            key={index}
            src={img.src}
            alt={img.alt}
            style={{
              ...styles.image,
              height: `${imageSize}vh`,
              width: `${imageSize * 0.9}vw`,
              left: `${img.position.left}%`,
              top: `${img.position.top}%`,
            }}
          />
        ))}
        <div style={{
          ...styles.userCountContainer,
          left: `${imagePositions.userCount.left}%`,
          top: `${imagePositions.userCount.top}%`,
        }}>
          <h2 style={styles.userCountTitle}>TOTAL USERS</h2>
          <div style={styles.userCountDisplay}>
            {displayCount.toString().padStart(8, '0').split('').map((digit, index) => (
              <span key={index} style={styles.digit}>{digit}</span>
            ))}
          </div>
        </div>

        <ContactForm 
          onSubmit={handleSubmit}
          formData={formData}
          handleInputChange={handleInputChange}
          isHovered={isHovered}
          setIsHovered={setIsHovered}
          styles={styles}
          imagePositions={imagePositions}
        />

        <Footer 
          handleFooterButtonClick={handleFooterButtonClick}
          styles={styles}
          imagePositions={imagePositions}
        />
      </div>
    </main>
  );
};

const styles = {
  main: {
    backgroundColor: 'black',
    height: '100vh',
    width: '100vw',
    overflow: 'auto',
    position: 'relative' as 'relative',
  },
  imageContainer: {
    minHeight: '900%', // Zvětšeno pro umístění všech obrázků a formuláře
    minWidth: '100%',
    position: 'relative' as 'relative',
  },
  image: {
    position: 'absolute' as 'absolute',
    objectFit: 'contain' as 'contain',
  },
  userCountContainer: {
    position: 'absolute' as 'absolute',
    textAlign: 'center' as 'center',
    color: 'white',
    zIndex: 10,
  },
  userCountTitle: {
    fontSize: '24px',
    marginBottom: '10px',
  },
  userCountDisplay: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    padding: '10px',
    borderRadius: '5px',
  },
  digit: {
    fontSize: '36px',
    fontWeight: 'bold',
    margin: '0 2px',
    padding: '5px',
    backgroundColor: 'transparent',
    border: '1px solid white',
    borderRadius: '3px',
    minWidth: '30px',
    color: 'white',
    textShadow: '0 0 5px rgba(255, 255, 255, 0.5)',
  },
  contactFormContainer: {
    position: 'absolute' as 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    padding: '30px',
    borderRadius: '10px',
    width: '600px',
  },
  contactFormSubtitle: {
    fontSize: '32px',
    marginBottom: '15px',
  },
  contactEmail: {
    marginBottom: '25px',
    fontSize: '18px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap: '25px',
  },
  formRow: {
    display: 'flex',
    gap: '25px',
  },
  formGroup: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
  label: {
    marginBottom: '8px',
    fontSize: '16px',
  },
  input: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: 'none',
    borderBottom: '1px solid #333',
    color: 'white',
    padding: '12px',
    fontSize: '16px',
  },
  select: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: 'none',
    borderBottom: '1px solid #333',
    color: 'white',
    padding: '12px',
    fontSize: '16px',
    appearance: 'none',
    backgroundImage: 'url("data:image/svg+xml;utf8,<svg fill=\'white\' height=\'24\' viewBox=\'0 0 24 24\' width=\'24\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 10l5 5 5-5z\'/><path d=\'M0 0h24v24H0z\' fill=\'none\'/></svg>")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 10px top 50%',
    backgroundSize: '20px auto',
  },
  textarea: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: 'none',
    borderBottom: '1px solid #333',
    color: 'white',
    padding: '12px',
    fontSize: '16px',
    minHeight: '120px',
    resize: 'vertical' as 'vertical',
  },
  submitButton: {
    backgroundColor: 'transparent',
    border: '2px solid white',
    color: 'white',
    padding: '12px 25px',
    fontSize: '18px',
    cursor: 'pointer',
    alignSelf: 'flex-start' as 'flex-start',
    transition: 'background-color 0.3s, color 0.3s',
    borderRadius: '5px',
  },
  footer: {
    position: 'absolute' as 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: '#fff',
    padding: '40px 0 20px',
    width: '100%',
    borderTop: '1px solid #ffffff',
  },
  footerContent: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  footerSection: {
    margin: '0 20px',
    textAlign: 'center' as 'center',
  },
  footerBottom: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    paddingTop: '20px',
  },
  footerButton: {
    backgroundColor: '#000000',
    color: '#ffffff',
    border: '1px solid #ffffff',
    borderRadius: '15px',
    padding: '8px 15px',
    margin: '0 10px',
    cursor: 'pointer',
    fontSize: '14px',
  },
  footerCopyright: {
    textAlign: 'center' as 'center',
    marginTop: '20px',
  },
};

interface ContactFormProps {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  formData: {
    name: string;
    email: string;
    subject: string;
    company: string;
    contactNumber: string;
    message: string;
  };
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  isHovered: boolean;
  setIsHovered: React.Dispatch<React.SetStateAction<boolean>>;
  styles: any;
  imagePositions: any;
}

const ContactForm: React.FC<ContactFormProps> = ({ 
  onSubmit, 
  formData, 
  handleInputChange, 
  isHovered, 
  setIsHovered, 
  styles, 
  imagePositions 
}) => {
  return (
    <div style={{
      ...styles.contactFormContainer,
      left: `${imagePositions.contactForm.left}%`,
      top: `${imagePositions.contactForm.top}%`,
    }}>
      <h1 style={styles.contactFormSubtitle}>get in touch</h1>
      <p style={styles.contactEmail}>info@nandezu.com</p>
     
      <form onSubmit={onSubmit} style={styles.form}>
        <div style={styles.formRow}>
          <div style={styles.formGroup}>
            <label htmlFor="name" style={styles.label}>your name *</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              placeholder="Let us know your name"
              style={styles.input}
              onChange={handleInputChange}
              required
            />
          </div>
          <div style={styles.formGroup}>
            <label htmlFor="email" style={styles.label}>email address *</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              placeholder="Your email id"
              style={styles.input}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
       
        <div style={styles.formGroup}>
          <label htmlFor="subject" style={styles.label}>subject *</label>
          <select
            id="subject"
            name="subject"
            value={formData.subject}
            style={styles.select as React.CSSProperties}
            onChange={handleInputChange}
            required
          >
            <option value="">please choose an option</option>
            <option value="option1">Company cooperation</option>
            <option value="option2">User</option>
            <option value="option3">Influencer cooperation</option>
          </select>
        </div>
       
        <div style={styles.formRow}>
          <div style={styles.formGroup}>
            <label htmlFor="company" style={styles.label}>Company name</label>
            <input
              type="text"
              id="company"
              name="company"
              value={formData.company}
              placeholder="company name (optional)"
              style={styles.input}
              onChange={handleInputChange}
            />
          </div>
          <div style={styles.formGroup}>
            <label htmlFor="contactNumber" style={styles.label}>Contact number</label>
            <input
              type="tel"
              id="contactNumber"
              name="contactNumber"
              value={formData.contactNumber}
              placeholder="Your contact number (optional)"
              style={styles.input}
              onChange={handleInputChange}
            />
          </div>
        </div>
       
        <div style={styles.formGroup}>
          <label htmlFor="message" style={styles.label}>your message *</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            placeholder="Anything else you want us to know."
            style={styles.textarea}
            onChange={handleInputChange}
            required
          />
        </div>
       
        <button 
          type="submit" 
          style={{
            ...styles.submitButton,
            backgroundColor: isHovered ? 'white' : 'transparent',
            color: isHovered ? 'black' : 'white',
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          let's go!
        </button>
      </form>
    </div>
  );
};

interface FooterProps {
  handleFooterButtonClick: (buttonName: string) => void;
  styles: any;
  imagePositions: any;
}

const Footer: React.FC<FooterProps> = ({ handleFooterButtonClick, styles, imagePositions }) => {
  return (
    <footer style={{
      ...styles.footer,
      left: `${imagePositions.footer.left}%`,
      top: `${imagePositions.footer.top}%`,
    }}>
      <div style={styles.footerContent}>
        <div style={styles.footerSection}>
          <p>Address: Werqwise San Mateo, CA 94402 4 West 4th Ave San Francisco CA</p>
        </div>
      </div>
      <div style={styles.footerBottom}>
        <button 
          style={styles.footerButton} 
          onClick={() => handleFooterButtonClick('Terms and Conditions')}
        >
          Terms and Conditions
        </button>
        <button 
          style={styles.footerButton} 
          onClick={() => handleFooterButtonClick('Privacy Policy')}
        >
          Privacy Policy
        </button>
        <button 
          style={styles.footerButton} 
          onClick={() => handleFooterButtonClick('FAQ')}
        >
          FAQ
        </button>
      </div>
      <div style={styles.footerCopyright}>
        <p>&copy; 2024 Nandezu Inc. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default PCHomeScreen;
      